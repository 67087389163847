import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faInfo,
  faSearch,
  faCopy,
  faCheck,
  faTimes,
  faClock,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faArrowsAltH,
  faChild,
  faUsersSlash,
  faUsers,
  faAddressCard,
  faBookOpen,
  faChartLine,
  faInfoCircle,
  faCalendarCheck,
  faCheckDouble,
  faLaptopCode,
  faListAlt,
  faBars,
  faChartBar,
  faSort,
  faSortUp,
  faSortDown,
  faDownload,
  faTrash,
  faCircleQuestion,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faDownload,
  faTrash,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faInfo,
  faSearch,
  faCopy,
  faCheck,
  faTimes,
  faClock,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faArrowsAltH,
  faChild,
  faUsersSlash,
  faUsers,
  faAddressCard,
  faBookOpen,
  faChartLine,
  faInfo,
  faInfoCircle,
  faCalendarCheck,
  faCheckDouble,
  faLaptopCode,
  faListAlt,
  faBars,
  faChartBar,
  faSort,
  faSortUp,
  faSortDown,
  faCircleQuestion,
  faMagnifyingGlass,
);

export { FontAwesomeIcon };
